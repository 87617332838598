import React, { useState } from 'react';
import GiftSuggestions from './GiftSuggestions';


function HomePage() {
    const initialFormData = {
        age: '',
        gender: '',
        hobbies: '',
        petOwnership: '',
        favoriteFoodAndDietaryPreferences: '',
        giftsToAvoid: '',
        country: 'US', // Default selection
        budget: '',
        additionalInfo: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [suggestions, setSuggestions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);  // New state for tracking loading status

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleReset = () => {
        setFormData(initialFormData);
        setSuggestions(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
    
        // Concatenate form data into a single string, excluding the 'country' field
        const userInput = `Age: ${formData.age}, Gender: ${formData.gender}, Hobbies: ${formData.hobbies}, Pet Ownership: ${formData.petOwnership}, Favorite Foods/Dietary Preferences: ${formData.favoriteFoodAndDietaryPreferences}, Gifts to Avoid: ${formData.giftsToAvoid}, Budget: ${formData.budget}, Additional Info: ${formData.additionalInfo}`;
    
        try {
            const response = await fetch('https://financeassistant-9dd2ad63af3b.herokuapp.com/get_gift_suggestions/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    description: userInput, 
                    country: formData.country // Send country as a separate field
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            if (data.error) {
                console.error('Backend error:', data.error);
                // Handle backend errors here
            } else {
                setSuggestions(data);
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            // Handle errors here
        }
        setIsLoading(false);  // Set loading to false when the request completes
    };

    return (
        <div>
            <h1>Welcome to Suggest-a-Gift</h1>
            <p>Fill in the details about your loved one to get gift suggestions!</p>
            <form onSubmit={handleSubmit}>
                <input 
                    type="number" 
                    name="age" 
                    value={formData.age} 
                    onChange={handleInputChange} 
                    placeholder="Age"
                />
                <select name="gender" value={formData.gender} onChange={handleInputChange}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-Binary</option>
                    <option value="prefer-not-to-say">Prefer Not to Say</option>
                </select>
                <input 
                    type="text" 
                    name="hobbies" 
                    value={formData.hobbies} 
                    onChange={handleInputChange} 
                    placeholder="Interests and Hobbies"
                />
                <input 
                    type="text" 
                    name="petOwnership" 
                    value={formData.petOwnership} 
                    onChange={handleInputChange} 
                    placeholder="Pet Ownership"
                />
                <input 
                    type="text" 
                    name="favoriteFoodAndDietaryPreferences" 
                    value={formData.favoriteFoodAndDietaryPreferences} 
                    onChange={handleInputChange} 
                    placeholder="Favorite Foods / Dietary Preferences"
                />
                <input 
                    type="text" 
                    name="giftsToAvoid" 
                    value={formData.giftsToAvoid} 
                    onChange={handleInputChange} 
                    placeholder="Gifts to Avoid"
                />
                <select name="country" value={formData.country} onChange={handleInputChange}>
                    <option value="US">USA</option>
                    <option value="GB">GBR</option>
                    <option value="CA">CAN</option>
                    <option value="AU">AUS</option>
                </select>
                <input 
                    type="number" 
                    name="budget" 
                    value={formData.budget} 
                    onChange={handleInputChange} 
                    placeholder="Budget for Gift"
                />
                <textarea 
                    name="additionalInfo" 
                    value={formData.additionalInfo} 
                    onChange={handleInputChange} 
                    placeholder="Additional Information"
                />
                <button type="submit" disabled={isLoading}>Get Suggestions</button>
                <button type="button" onClick={handleReset} className="reset-button">Reset</button>
            </form>
            {isLoading && <div className="loading-spinner"></div>}  {/* Animated loading spinner */}
            {!isLoading && suggestions && <GiftSuggestions suggestion={suggestions.suggestion} amazonResults={suggestions.amazon_results} />}
        </div>
    );
}
export default HomePage;
