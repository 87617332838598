import React from 'react';

function GiftSuggestions({ suggestion, amazonResults }) {
    return (
        <div>
            <h2>Gift Suggestion</h2>
            <p>{suggestion ? suggestion : 'No suggestions found. Try a different description.'}</p>

            <h3>Product Options from Amazon</h3>
            {amazonResults && amazonResults.status === 'OK' && amazonResults.data.products.length > 0 ? (
                <ul>
                    {amazonResults.data.products.map((product, index) => (
                        <li key={index}>
                            <a href={product.product_url} target="_blank" rel="noopener noreferrer">
                                {product.product_title} - {product.product_price}
                            
                            </a>
                            <a href={product.product_url} target="_blank" rel="noopener noreferrer">
                                <img src={product.product_photo} alt={product.product_title} />
                            </a>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No products found on Amazon.</p>
            )}
        </div>
    );
}

export default GiftSuggestions;
